// Save conflict modal box with animate.css
<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"
            :class="{ 'is-flex-items-center' : showIcon}">
            <span v-if="showIcon"
              class="icon is-medium"
              :class="`has-text-${iconType}`"
              style="margin-right: 0.5rem">
              <i class="mdi mdi-36px"
                :class="`${iconName}`" />
            </span>
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <slot name="text-content" />
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button"
            :class="[{ 'tooltip' : okTooltip !== ''}, okClass]"
            :data-tooltip="okTooltip"
            @click="ok()">{{ okText }}
          </button>
          <button class="button"
            :class="[{ 'tooltip' : cancelTooltip !== ''}, cancelClass]"
            :data-tooltip="cancelTooltip"
            @click="cancel()">{{ cancelText }}
          </button>
          <button v-if="showClose"
            class="button"
            :class="[{ 'tooltip' : closeTooltip !== ''}, closeClass]"
            :data-tooltip="closeTooltip"
            @click="close()">{{ closeText }}
          </button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>
import BaseModalEx from './BaseModalEx'

export default {
  name: 'ConfirmModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    okText: {
      type: String,
      default: 'Ok'
    },
    okTooltip: {
      type: String,
      default: ''
    },
    okClass: {
      type: String,
      default: 'is-primary'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    cancelTooltip: {
      type: String,
      default: ''
    },
    cancelClass: {
      type: String,
      default: ''
    },
    closeText: {
      type: String,
      default: 'Close'
    },
    closeTooltip: {
      type: String,
      default: ''
    },
    closeClass: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    cancel() {
      this.$emit('cancel')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
