// Add remarks modal conflict modal box with animate.css
<template>
  <base-modal-ex
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-height="55"
    :min-width="30"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title">
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <div class="control">
          <input
            class="is-checkradio is-circle is-success"
            :class="{ 'has-background-color': addToRemark }"
            id="remark1"
            name="remark1"
            type="checkbox"
            v-model="addToRemark">
          <label for="remark1">Remark</label>
          <input
            class="is-checkradio is-circle is-success"
            :class="{ 'has-background-color': addToPrintedRemark }"
            id="remark2"
            name="remark2"
            type="checkbox"
            v-model="addToPrintedRemark">
          <label for="remark2">Printed Remark</label>
        </div>
      </div>
      <!-- <div class="content"> -->
      <div
        v-if="showRecurringRemark && recurringRemarks"
        class="field">
        <div class="control">
          <multiselect
            v-model="selectedRemark"
            track-by="recurringRemarkId"
            label="remarkDescription"
            placeholder="Select a remark to add"
            :options="recurringRemarks"
            :searchable="true"
            :allow-empty="true">
            <span
              class="has-text-danger"
              slot="noResult">Remark not found.</span>
          </multiselect>
        </div>
      </div>
      <div
        v-if="showRemark"
        class="field">
        <label class="label">Remark</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="remark"
            placeholder="Add remark here"
            v-focus-inserted />
        </div>
      </div>
      <div
        v-if="showPrintedRemark"
        class="field">
        <label class="label">Printed Remark</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="printedRemark"
            placeholder="Add printed remark here"
            v-focus-inserted="{ focus: !showRemark }" />
        </div>
      </div>
      <!-- </div> -->
    </section>
    <footer
      class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button
        class="button is-primary tooltip is-tooltip-topright"
        @click="add()"
        data-tooltip="Add remark or printed remark">Add Remark</button>
      <button
        class="button tooltip is-tooltip-topright"
        @click="close()"
        data-tooltip="Close &amp; don't add">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import BaseModalEx from './BaseModalEx'
import { FocusInserted } from '@/components/directives'
import Multiselect from 'vue-multiselect'
import RecurringRemarkService from '@/views/recurringremarks/RecurringRemarkService'

export default {
  name: 'RemarkModal',
  components: {
    BaseModalEx,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showRecurringRemark: {
      type: Boolean,
      default: false
    },
    showPrintedRemark: {
      type: Boolean,
      default: true
    },
    showRemark: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: this.active || false,
      remark: '',
      printedRemark: '',
      selectedRemark: null,
      recurringRemarks: null,
      addToRemark: true,
      addToPrintedRemark: true
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    selectedRemark(value) {
      if (value && this.showRemark && this.addToRemark) {
        this.remark = value.remarkDescription
      }
      if (value && this.showPrintedRemark && this.addToPrintedRemark) {
        this.printedRemark = value.remarkDescription
      }
    },
    addToRemark(value) {
      if (!value) {
        this.remark = ''
      } else if (this.selectedRemark) {
        this.remark = this.selectedRemark.remarkDescription
      }
    },
    addToPrintedRemark(value) {
      if (!value) {
        this.printedRemark = ''
      } else if (this.selectedRemark) {
        this.printedRemark = this.selectedRemark.remarkDescription
      }
    }
  },
  async created() {
    if (this.showRecurringRemark) {
      this.recurringRemarks = await RecurringRemarkService.getRemarks('')
    }
  },
  methods: {
    close() {
      this.$emit('cancel')
    },
    add() {
      this.$emit('add', this.remark, this.printedRemark)
    }
  }
}
</script>
